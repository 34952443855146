import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { SixtyFps, Assignment, Cloud, Inventory2, Filter9, VerifiedUser, SendToMobile, ManageSearch, Gavel, WhatsApp } from '@mui/icons-material';
import { navigate } from "gatsby"
import Ad from '../components/Ad'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react'
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
        /**
         * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
         * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
         * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
         * proper interaction with the underlying content.
         */
        position: 'relative',
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight({ children }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [childComponent, setChildComponent] = useState([])

    const handleDrawerOpen = () => {

        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }

    };

    const handleClose = () => {

    };

    const openMenu = Boolean(anchorEl);
    const id = openMenu ? 'simple-popover' : undefined;

    useEffect(() => {

        const childArr = React.Children.toArray(children)
        if (childArr.length === 1) {
            setChildComponent([<></>, childArr[0], <></>])
        }

        if (childArr.length === 2) {
            setChildComponent([<></>, childArr[0], childArr[1]])
        }

        if (childArr.length === 3) {
            setChildComponent([childArr[0], childArr[1], childArr[2]])
        }
    }, [children])

    return (
        <>
            <MenuList>
            <MenuItem onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <Filter9 fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Home</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/ask')}>
                    <ListItemIcon>
                        <Filter9 fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>五行数字 Five Element Number</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/mobile')}>
                    <ListItemIcon>
                        <SendToMobile fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>数字能量 Number Energy</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/ask')}>
                    <ListItemIcon>
                        <SixtyFps fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>数字方 Number For Health</ListItemText>
                </MenuItem>
            </MenuList>
        </>
    );
}

