import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { SixtyFps, Assignment, Cloud, Inventory2, Filter9, VerifiedUser, SendToMobile, ManageSearch, Gavel, WhatsApp } from '@mui/icons-material';
import { navigate } from "gatsby"
import Ad from '../components/Ad'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react'
import Menu from '../components/Menu'
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [childComponent, setChildComponent] = useState([])

  const handleDrawerOpen = () => {

    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }

  };

  const handleClose = () => {

  };

  const openMenu = Boolean(anchorEl);
  const id = openMenu ? 'simple-popover' : undefined;

  useEffect(() => {

    const childArr = React.Children.toArray(children)
    if(childArr.length === 1) {
      setChildComponent([<></>, childArr[0], <></>])
    }

    if(childArr.length === 2) {
      setChildComponent([<></>, childArr[0], childArr[1]])
    }

    if(childArr.length === 3) {
      setChildComponent([childArr[0], childArr[1], childArr[2]])
    }
  }, [children])
 
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={false} color="inherit" >
          <Toolbar>

            <Box>
              <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">

              </Typography>
            </Box>
            <Box ml="auto">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleClick}
              >
                <MenuIcon sx={{ cursor: "pointer" }} />
                <Popover
                  id={id}
                  open={openMenu}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 2 }}>

                    <Menu />
                  </Typography>
                </Popover>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Box display="flex" marginTop="64px" flexDirection="column" width="100%" alignItems="center">
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="center">
                <img src="/images/logo2.png" style={{ width: "256px" }} />
              </Box>
            </Grid>



          </Grid>
          { children }
          <Box display="flex" padding="16px" flexDirection="column" style={{ backgroundColor: 'black', color: "white" }} >
            <h3>Do your own Research</h3>

            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              Under no circumstances OpenFengShui be held responsible or liable in any way for any claims, damages, losses, expenses, costs or liabilities whatsoever (including, without limitation, any direct or indirect damages for loss of profits, business interruption or loss of information) resulting or arising directly or indirectly from your use of or inability to use this website or any websites linked to it, or from your reliance on the information and material on this website, even if the OpenFengShui has been advised of the possibility of such damages in advance.
              <Box display="flex" flexDirection="row" gap="8px">
                <a style={{ textDecoration: 'none', color: "blue" }} href="/term">Privacy</a>
                <a style={{ textDecoration: 'none', color: "blue" }} href="/term">Term and Condition</a>
              </Box>

            </Box>

          </Box>
        </Box >

      </Box >

    </>
  );
}

